











































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { OrderListItem } from '@/models/orders';
import OrderDropdown from '@/components/orders/OrderDropdown.vue';
import { DropdownItem } from '@/models/dropdown';
import { OrderStatus } from '@/utils/constants';
@Component({
  components: { OrderDropdown },
})
export default class OrderItem extends Vue {
  @Prop({ required: true })
  data!: OrderListItem;

  @Prop()
  isCheckboxSelected!: boolean;

  actionDropdownText = `Action`;
  isProductDropdownActive = false;
  isOrderItemSelected = false;

  get orderNumber() {
    return this.data.invoiceNumber;
  }

  get orderDate() {
    return this.data.date;
  }

  get orderStatus() {
    return this.data.orderStatus.name;
  }

  get customerName() {
    return this.data.billingInfo.name;
  }

  get customerEmail() {
    return this.data.billingInfo.email;
  }

  get customerPhoneNumber() {
    return this.data.billingInfo.phone;
  }

  get customerAddress() {
    var address = '';
    if(this.data.billingInfo.address) address += this.data.billingInfo.address + ',';
    if(this.data.billingInfo.city) address += this.data.billingInfo.city + ',';
    if(this.data.billingInfo.postcode) address += this.data.billingInfo.postcode + ',';
    if(this.data.billingInfo.state) address += this.data.billingInfo.state + ',';
    if(this.data.billingInfo.country) address += this.data.billingInfo.country;
    return `${address}`;
  }

  get products() {
    return this.data.items;
  }

  get paymentType() {
    return this.data.paymentType ? this.data.paymentType : `None`;
  }

  get paymentAmount() {
    return this.data.grandTotal;
  }

  get isPaidStatus() {
    return this.orderStatus === OrderStatus.paid;
  }

  get isPendingStatus() {
    return this.orderStatus === OrderStatus.pending;
  }

  get isFailedStatus() {
    return this.orderStatus === OrderStatus.failed;
  }

  get isTerminatedStatus() {
    return this.orderStatus === OrderStatus.terminated;
  }

  get actions() {
    return [
      {
        text: `Print Order`,
        value: `print`,
        uniqueKey: `_print`,
      },
    ];
  }

  @Watch(`isCheckboxSelected`)
  updateCheckboxState() {
    this.isOrderItemSelected = this.isCheckboxSelected;
  }

  @Emit(`select`)
  updateSelection() {
    return this.data;
  }

  @Emit(`parse`)
  parseOrder(data: DropdownItem) {
    return {
      itemDetails: this.data,
      method: data,
    };
  }
}
