import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic } from 'buefy';
import { SettingDetails, SettingResponse } from '@/models/settings';

@Module({
    namespaced: true,
    dynamic: true,
    name: `setting.detail`,
    store,
})
class SettingDetailStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: boolean | null = null;
    settingDetails: SettingDetails | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setSettingDetails(payload: SettingDetails | null) {
        this.settingDetails = payload;
    }

    @Mutation
    setResponse(payload: boolean | null ) {
        this.response = payload;
    }

    @Action
    retrieveSettingDetails() {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setSettingDetails`, null);
        axios.get<SettingResponse>(`/setting`)
            .then((response) => {
                if (response.data.code === 200) {
                    this.context.commit(`setSettingDetails`, response.data.data);
                }

                if (response.data.code >= 400) {
                    ToastProgrammatic.open({
                        type: `is-danger`,
                        duration: 3000,
                        message: `There is an issue retrieving settings. Please try again`
                    });
                }
            }).catch((error) => {
                this.context.commit(`setError`, error);
            });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    updateSettingDetails(payload: SettingDetails) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        const updatePayload: Omit<SettingDetails, `id`> = { 
            coursePurchaseCutoffDate: payload.coursePurchaseCutoffDate,
            adminEmail: payload.adminEmail,
            careerEmail: payload.careerEmail,
            salesEmail: payload.salesEmail,
            supportEmail: payload.supportEmail
        };
        axios.put<SettingResponse>(`/setting/${payload.id}`, updatePayload)
            .then((response) => {
                if (response.data.code === 200 && response.data.message === `Success`) {
                    ToastProgrammatic.open({
                        type: `is-success`,
                        duration: 3000,
                        message: `Settings successfully updated.`
                    });
                    this.context.commit(`setResponse`, response.data.data);
                }
    
                if (response.data.code >= 400) {
                    ToastProgrammatic.open({
                        type: `is-danger`,
                        duration: 3000,
                        message: `There is an issue updating settings. Please try again`
                    });
                }
            }).catch((error) => {
                this.context.commit(`setError`, error);
            });
        this.context.commit(`updateLoadingStatus`, false);
    }

}

export default getModule(SettingDetailStore);