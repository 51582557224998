




















































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import OrderDropdown from '@/components/orders/OrderDropdown.vue';
import OrderSearch from '@/components/orders/OrderSearch.vue';
import OrderItem from '@/components/orders/OrderItem.vue';
import OrderCheckboxDropdown from '@/components/orders/CheckboxOrderDropdown.vue';
import { DropdownItem } from '@/models/dropdown';
import { GeneratePDF } from '@/utils/generatePDF';
import PaginationControls from '@/components/common/PaginationControls.vue';
import { OrderFetchParams, OrderListItem, PaymentGateway } from '@/models/orders';
import { OrderStores } from '@/store/orders';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
import { OrderStatus } from '@/utils/constants';
import { convertDateToOrderFormat, formatDateToMMMDDYYYY } from '@/utils/moment';
import { GenerateExcelAndCsv } from '@/utils/generateExcel';
import { PaginationDetails } from '@/models/generic';
import { SettingStores } from '@/store/settings';
@Component({
    components: { 
        Page, 
        OrderDropdown, 
        OrderSearch, 
        OrderItem, 
        OrderCheckboxDropdown, 
        EmptyPlaceholder,
        PaginationControls,
    },
})
export default class Orders extends Vue {
    // UI State
    filterDropdownText = `Filter`;
    actionDropdownText = `Actions`;
    isActionDropdownShown = false;
    areSelectedCheckboxesValid = false;
    itemCheckboxState: {[id : string] : boolean} = {};
    pdfGenerator = new GeneratePDF();
    excelGenerator = new GenerateExcelAndCsv();
    orderItems: OrderListItem[] = [];
    noInvalidCheckboxes = false;
    isAllDropdownReset = false;
    // Pagination
    currentPage = 1;
    currentLimit = 10;
    searchQuery = ``;
    filter = 0;
    // Stores
    orderListStore = OrderStores.list;
    settingStore = SettingStores.detail;

    get filters() {
        return [
            {
                text: `All`,
                value: `0`,
                uniqueKey: `_all`,
            },
            {
                text: `Pending`,
                value: `1`,
                uniqueKey: `_pending`,
            },
            {
                text: `Failed`,
                value: `2`,
                uniqueKey: `_failed`,
            },
            {
                text: `Paid`,
                value: `3`,
                uniqueKey: `_paid`,
            },
            {
                text: `Terminated`,
                value: `4`,
                uniqueKey: `_terminated`,
            },
        ];
    }

    get actions() {
        return [
            {
                text: `Export Selected (Excel)`,
                value: `export_excel`,
                uniqueKey: `_export_excel`
            },
            {
                text: `Export Selected (CSV)`,
                value: `export_csv`,
                uniqueKey: `_export_csv`
            },
            {
                text: `Print Selected`,
                value: `print`,
                uniqueKey: `_print`
            },
        ];
    }

    get orders() {
        return this.orderItems;
    }

    get totalCount() {
        return this.orderListStore.totalRecords;
    }

    mounted() {
        this.retrieveData();
        this.settingStore.retrieveSettingDetails();
    }

    updatePagination(payload: PaginationDetails) {
        this.currentPage = payload.page;
        this.currentLimit = payload.limit;
        this.isAllDropdownReset = true;
        this.retrieveData();
    }

    retrieveData() {
        const payload: Partial<OrderFetchParams> = {
            page: this.currentPage,
            limit: this.currentLimit,
        };

        if (this.filter > 0) {
            payload.filter = this.filter;
        }

        if (this.searchQuery.length > 0) {
            payload.keyword = this.searchQuery;
        }

        this.orderListStore.retrieveOrders(payload);
    }

    @Watch(`settingStore.settingDetails`)
    getSupportEmail() {
        if (this.settingStore.settingDetails) {
            this.pdfGenerator.setSupportEmail(this.settingStore.settingDetails.supportEmail);
        }
    }

    @Watch(`orderListStore.response`)
    populateOrders() {
        if (this.orderListStore.response.length > 0) {
            this.orderItems = [];
            this.itemCheckboxState = {};
            this.orderListStore.response.forEach((data) => {
                this.orderItems.push({
                    id: data.id,
                    date: convertDateToOrderFormat(new Date(Number(data.date) * 1000).toISOString()),
                    referenceNumber: data.refNo,
                    paymentType: data.paymentMethod?.name,
                    grandTotal: `RM ${data.totalPriceInfo.grandtotal}`,
                    billingInfo: data.billingInfo,
                    orderStatus: data.orderStatus,
                    invoiceNumber: data.invoiceNumber,
                    items: data.items,
                });
                this.itemCheckboxState[data.id.toString()] = false;
            });
            this.updateOrderSelection(false);
        }
    }

    updateOrderSelection(value: boolean) {
        this.isActionDropdownShown = value === true;
        // If True, select all
        if (this.isActionDropdownShown) {
            Object.keys(this.itemCheckboxState).forEach((key) => {
                this.itemCheckboxState[key] = true;
            });
        } else {
            Object.keys(this.itemCheckboxState).forEach((key) => {
                this.itemCheckboxState[key] = false;
            });
        }

        this.validateCheckboxes();
    }

    updateItemSelection(payload: OrderListItem) {
        this.itemCheckboxState[payload.id.toString()] = !this.itemCheckboxState[payload.id.toString()];
        // Filter Valid Orders
        const orders = this.grabPaidOrders();
        // Grab Marked Orders
        const markedOrders = this.grabMarkedOrders();
        this.areSelectedCheckboxesValid = false;
        if (markedOrders.length === orders.length) {
            this.areSelectedCheckboxesValid = true;
        }
        // Validate Checkboxes
        this.validateCheckboxes();
}

    filterOrders(data: DropdownItem) {
        this.filterDropdownText = data.text;
        this.filter = Number(data.value);
        this.orderItems = [];
        this.isAllDropdownReset = true;

        this.currentPage = 1;
        this.currentLimit = 10;
        this.retrieveData();
    }

    searchOrders(query: string) {
        this.searchQuery = query;
        this.isAllDropdownReset = true;
        this.orderItems = [];
        this.isActionDropdownShown = false;
        this.areSelectedCheckboxesValid = false;

        this.currentPage = 1;
        this.currentLimit = 10;
        this.retrieveData();
    }

    parseOrder(payload: { itemDetails: OrderListItem, method: DropdownItem}) {
        if (payload.method.value === `print`) {
            const index = this.orderListStore.response.findIndex((data) => {
                return data.id === payload.itemDetails.id;
            });

            if (index > -1 && this.orderListStore.response[index].gateway === PaymentGateway.recurring) {
                this.pdfGenerator.setPdfData(this.orderListStore.response[index]);
                this.pdfGenerator.generateRecurringPaymentPdf();
                const date = convertDateToOrderFormat(new Date(Number(this.orderListStore.response[index].date) * 1000).toISOString());
                const fileName = `Order #${this.orderListStore.response[index].invoiceNumber} - ${date}`;
                this.pdfGenerator.downloadPdf(fileName);
            }

            if (index > -1 && this.orderListStore.response[index].gateway === PaymentGateway.onetime) {
                this.pdfGenerator.setPdfData(this.orderListStore.response[index]);
                this.pdfGenerator.generateOneTimePaymentPdf();
                const date = convertDateToOrderFormat(new Date(Number(this.orderListStore.response[index].date) * 1000).toISOString());
                const fileName = `Order #${this.orderListStore.response[index].invoiceNumber} - ${date}`;
                this.pdfGenerator.downloadPdf(fileName);
            }
        }
    }

    performAction(payload: DropdownItem) {
        const orders = this.grabPaidOrders();
        if (payload.value === 'print') {
            // Generate PDFs
            for (let index = 0; index < orders.length; index++) {
                if (orders[index].gateway === PaymentGateway.recurring) {
                    this.pdfGenerator.setPdfData(orders[index]);
                    this.pdfGenerator.generateRecurringPaymentPdf();
                    const date = convertDateToOrderFormat(new Date(Number(this.orderListStore.response[index].date) * 1000).toISOString());
                    const fileName = `Order #${this.orderListStore.response[index].invoiceNumber} - ${date}`;
                    this.pdfGenerator.downloadPdf(fileName);
                }

                if (orders[index].gateway === PaymentGateway.onetime) {
                    this.pdfGenerator.setPdfData(orders[index]);
                    this.pdfGenerator.generateOneTimePaymentPdf();
                    const date = convertDateToOrderFormat(new Date(Number(this.orderListStore.response[index].date) * 1000).toISOString());
                    const fileName = `Order #${this.orderListStore.response[index].invoiceNumber} - ${date}`;
                    this.pdfGenerator.downloadPdf(fileName);
                }
            }
        } else if (payload.value === 'export_excel') {
            // Export excel
            this.excelGenerator.setData(orders);
            this.excelGenerator.fitDataToWorkbook();
            this.excelGenerator.exportWorkbookToExcel(`Export_Order_${formatDateToMMMDDYYYY(new Date())}`);
        } else if (payload.value === 'export_csv') {
            // Export excel
            this.excelGenerator.setData(orders);
            this.excelGenerator.fitDataToWorkbook();
            this.excelGenerator.exportWorkbookToCSV(`Export_Order_${formatDateToMMMDDYYYY(new Date())}`);
        }
    }

    validateCheckboxes() {
        const orders = this.grabPaidOrders();
        this.noInvalidCheckboxes = false;
        if (orders.length > 0 && orders.length <= this.orderListStore.response.length) {
            this.noInvalidCheckboxes = true;
        }
    }

    grabPaidOrders() {
        // Filter out selected checkboxes
        const selectedCheckboxes = Object.entries(this.itemCheckboxState).filter(([key , value] ) => { 
            if (value) {
                return key;
            }
        }).map((data) => {
            return data[0];
        });
        // Grab orders based on ids from checkboxes
        const orders = this.orderListStore.response.filter((orderItem) => {
            if (selectedCheckboxes.includes(orderItem.id.toString()) && orderItem.orderStatus.name === OrderStatus.paid) {
                return orderItem;
            }
        });
        return orders;
    }

    grabMarkedOrders() {
        // Filter out selected checkboxes
        const selectedCheckboxes = Object.entries(this.itemCheckboxState).filter(([key , value] ) => { 
            if (value) {
                return key;
            }
        }).map((data) => {
            return data[0];
        });
        // Grab orders based on ids from checkboxes
        const orders = this.orderListStore.response.filter((orderItem) => {
            if (selectedCheckboxes.includes(orderItem.id.toString())) {
                return orderItem;
            }
        });
        return orders;
    }
}
