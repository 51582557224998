import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { OrderFetchParams, OrderItemResponse, OrderResponse } from '@/models/orders';

@Module({
    namespaced: true,
    dynamic: true,
    name: `orders.list`,
    store,
})
class OrderListStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: OrderItemResponse[] = [];
    totalRecords = 0;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: OrderItemResponse[]) {
        this.response = payload;
    }

    @Mutation
    setTotalRecords(payload: number) {
        this.totalRecords = payload;
    }

    @Action
    retrieveOrders(params: Partial<OrderFetchParams>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, []);
        this.context.commit(`setTotalRecords`, 0);
        axios.get<OrderResponse>(`/order`, {
            params
        }).then((response) => {
            if (response.data.code === 200 && 'objects' in response.data.data) {
                this.context.commit(`setTotalRecords`, response.headers[`x-total-records`]);
                this.context.commit(`setResponse`, response.data.data.objects);
            }

            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with fetching orders. Please try again`,
                });
            }
        })
        .catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

}

export default getModule(OrderListStore);