







import { Vue, Component, Emit } from 'vue-property-decorator';
@Component({})
export default class OrderSearch extends Vue {
  searchInput = ``;

  @Emit(`search`)
  emitQuery() {
    return this.searchInput;
  }
}
