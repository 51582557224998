























import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { DropdownItem } from '@/models/dropdown';
@Component({})
export default class OrderDropdown extends Vue {
  @Prop({ required: true })
  data!: DropdownItem[];

  @Prop({ required: true })
  selectedText!: string;

  @Prop({ default: false })
  isDisabled!: boolean;

  @Prop()
  previousSelectedValue!: string;

  isDropdownShown = false;
  selectedValue = ``;
  selectedDropdownItem: DropdownItem = {
    text: ``,
    value: ``,
    uniqueKey: ``,
  };

  get displayText() {
    return this.selectedText;
  }

  // Close Dropdown function
  closeDropdown(event: any) {
    if (!this.$el.contains(event.target)) {
      this.isDropdownShown = false;
    }
  }

  created() {
    document.addEventListener('click', this.closeDropdown);
  }

  beforeDestroy() {
    document.removeEventListener(`click`, this.closeDropdown);
  }

  @Watch(`previousSelectedValue`)
  updateCurrentValue() {
    if (this.previousSelectedValue.length > 0) {
      const itemIndex = this.data.findIndex((item) => {
        return item.value === this.previousSelectedValue;
      });
      if (itemIndex > -1) {
        this.selectedDropdownItem = this.data[itemIndex];
        this.updateSelectedItem(this.data[itemIndex]);
      }
    }
  }

  updateSelectedState(selectedItem: DropdownItem) {
    this.selectedDropdownItem = selectedItem;
    this.selectedValue = selectedItem.value;
    this.updateSelectedItem(selectedItem);
  }

  @Emit(`select`)
  updateSelectedItem(selectedItem: DropdownItem) {
    this.isDropdownShown = false;
    this.selectedValue = selectedItem.value;
    return selectedItem;
  }
}
