import pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions, CustomTableLayout, Content, TableCell } from 'pdfmake/interfaces';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { OrderItemResponse, OrderType } from '@/models/orders';
import { convertDateToOrderFormat } from './moment';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
export enum PDFType {
  recurring = `Recurring`,
  oneTime = `One Time Payment`,
}

/*
PDF Generate Functions using PDFMake
*/

export class GeneratePDF {
  constructor() {
    this.docDefinition = {
      content: [],
    };
    this.supportEmail = '';
    this.data = null;
  }

  private docDefinition: TDocumentDefinitions;
  private data: OrderItemResponse | null;
  private supportEmail: string;

  private get imageHeader() {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="180.558" height="46.641" viewBox="0 0 180.558 46.641">
            <g id="Logo" transform="translate(203.29 52.514)">
            <g id="Group_404" data-name="Group 404" transform="translate(-203.29 -52.514)">
                <path id="Path_487" data-name="Path 487" d="M39.13,0H7.512A7.534,7.534,0,0,0,0,7.512V39.13a7.534,7.534,0,0,0,7.512,7.512H39.13a7.534,7.534,0,0,0,7.512-7.512V7.512A7.534,7.534,0,0,0,39.13,0ZM5.965,6.13V5.859h.214C6.1,5.946,6.033,6.037,5.965,6.13ZM26.76,39.791H19.888V20.266a14.628,14.628,0,0,1,3.357,5.177c.02.056.069.213.069.216l.007.023s.052-.177.075-.239a14.68,14.68,0,0,1,3.364-5.187ZM39.692,13.231H32.319c-4.077,0-7.552,4.139-8.924,7.795-.023.072-.049.147-.075.219,0-.01-.052-.147-.075-.219-1.371-3.655-4.843-7.795-8.92-7.795H6.95V6.85h7.375c4.084,0,7.562,3.554,8.93,7.6l.069.222.066-.226c1.368-4.051,4.846-7.6,8.93-7.6h7.372Z" fill="#65cd7d"/>
                <g id="Group_403" data-name="Group 403" transform="translate(57.324 3.452)">
                <path id="Path_488" data-name="Path 488" d="M146.37,47.341q-2.031.169-4.992.169c-2.859,0-4.872-.659-6.121-1.684s-1.986-2.8-1.917-5.338V21.705h-4.258V16.037h4.258V10.929l8.094-3.156v8.264h4.936v5.669h-4.936V39.135a1.451,1.451,0,0,0,.634,1.41,6.589,6.589,0,0,0,2.638.338l1.664-.056Z" transform="translate(-129.082 -7.773)" fill="#65cd7d"/>
                <path id="Path_489" data-name="Path 489" d="M239.6,26.381h7.829l7.667,23.289,7.379-23.289H270.2L259.114,57.065h-8.432Z" transform="translate(-190.519 -18.117)" fill="#65cd7d"/>
                <g id="Group_402" data-name="Group 402" transform="translate(83.318 8.263)">
                    <path id="Path_490" data-name="Path 490" d="M324.792,56.841H316.7V26.381h8.094Z" transform="translate(-316.698 -26.381)" fill="#65cd7d"/>
                </g>
                <path id="Path_491" data-name="Path 491" d="M362.339,34.551a3.359,3.359,0,0,0-1.467-2.848,6.461,6.461,0,0,0-3.894-1.072,7.007,7.007,0,0,0-3.655.8,2.5,2.5,0,0,0-1.312,2.242q0,1.015,1.495,1.749a27.578,27.578,0,0,0,5.218,1.551q7.447,1.665,9.958,3.638t2.518,6.2a8.656,8.656,0,0,1-3.653,7.262q-3.653,2.75-9.633,2.75-7.08,0-10.9-2.707a9.692,9.692,0,0,1-4.076-7.9h8.235a3.984,3.984,0,0,0,1.876,3.314,8.646,8.646,0,0,0,4.865,1.2,7.3,7.3,0,0,0,3.8-.832,2.592,2.592,0,0,0,1.367-2.327A2.1,2.1,0,0,0,361.75,45.8a20.288,20.288,0,0,0-4.654-1.438q-7.784-1.748-10.408-3.694-2.62-1.917-2.62-5.951a9.1,9.1,0,0,1,3.4-7.375,13.607,13.607,0,0,1,8.952-2.806q6.26,0,10.094,2.75a8.526,8.526,0,0,1,3.836,7.262Z" transform="translate(-247.966 -17.093)" fill="#65cd7d"/>
                <path id="Path_492" data-name="Path 492" d="M200.142,26.955q-3.318-2.415-9.04-2.413-6.6,0-10.013,2.678t-3.664,8.066h7.754A4.249,4.249,0,0,1,186.56,32.1a5.33,5.33,0,0,1,3.612-1.128,7.482,7.482,0,0,1,3.976.874,2.729,2.729,0,0,1,1.325,2.029,2.848,2.848,0,0,1-.241,1.5,1.91,1.91,0,0,1-.914,1.017,11.354,11.354,0,0,1-3.47.957l-5.329.736a11.11,11.11,0,0,0-6.824,3.173,9.486,9.486,0,0,0-2.342,6.726,8.381,8.381,0,0,0,2.6,6.413,9.576,9.576,0,0,0,6.852,2.441,12.313,12.313,0,0,0,9.847-4.482c.008-.008.016-.02.024-.028l.008.016v3.705h.724v0h7.054v-22.5A7.726,7.726,0,0,0,200.142,26.955Zm-4.613,17.751a7.148,7.148,0,0,1-.309,2.029,4.444,4.444,0,0,1-.759,1.579,7.718,7.718,0,0,1-6.207,2.738,3.614,3.614,0,0,1-2.761-1.044,4.006,4.006,0,0,1-.989-2.876,3.547,3.547,0,0,1,1.1-2.666,6.178,6.178,0,0,1,3.216-1.452l2.987-.565a13.057,13.057,0,0,0,3.723-1.266Z" transform="translate(-155.36 -17.095)" fill="#65cd7d"/>
                <rect id="Rectangle_883" data-name="Rectangle 883" width="8.205" height="6.042" transform="translate(83.263 0)" fill="#65cd7d"/>
                </g>
            </g>
            </g>
        </svg>      
        `;
  }

  private get footerTitle(): string {
    return `Thank you for your order!`;
  }

  private get companyWebsiteLink(): string {
    return `www.tavis.live`;
  }

  private get invoiceNumber() {
    return `#${this.data?.invoiceNumber}`;
  }

  private get paymentDate() {
    return convertDateToOrderFormat(new Date(Number(this.data?.date) * 1000).toISOString());
  }

  private get paymentMonth() {
    return new Date(Number(this.data?.date) * 1000).toLocaleString('default', { month: 'long' });
  }

  private get paymentMethod() {
    return this.data?.paymentMethod ? this.data.paymentMethod.name : `None`;
  }

  private get payeeName() {
    return this.data?.billingInfo.name;
  }

  private get payeePhoneNumber() {
    return this.data?.billingInfo.phone;
  }

  private get payeeEmail() {
    return this.data?.billingInfo.email;
  }

  private get payeeAddress() {
    return `${this.data!.billingInfo.address},`;
  }

  private get payeeCity() {
    return `${this.data!.billingInfo.city},`;
  }

  private get payeePostcodeAndState() {
    return `${this.data!.billingInfo.postcode}, ${this.data!.billingInfo.state},`;
  }

  private get payeeCountry() {
    return this.data!.billingInfo.country;
  }

  private get items() {
    return this.data!.items;
  }

  private get subtotalValue() {
    if (this.data?.totalPriceInfo) {
      const value =
        Number(this.data?.totalPriceInfo.originalPrice) -
        (Number(this.data?.totalPriceInfo.discount) +
          Number(this.data?.totalPriceInfo.bundleDiscount));
      return value.toFixed(2);
    }
    return '-';
  }

  private get totalValue() {
    let value = (
      Number(this.subtotalValue) - Number(this.data?.totalPriceInfo.promoDiscount)
    ).toString();
    if (!value.includes('.')) {
      value = value + `.00`;
      return value;
    }
    return value ? value : `-`;
  }

  private get generalLayout(): CustomTableLayout {
    return {
      hLineWidth: function() {
        return 1;
      },
      vLineWidth: function() {
        return 0;
      },
      hLineColor: function() {
        return `black`;
      },
      paddingLeft: function() {
        return 0;
      },
      paddingRight: function() {
        return 0;
      },
      defaultBorder: false,
    };
  }

  setSupportEmail(supportEmail: string) {
    this.supportEmail = supportEmail;
  }

  downloadPdf(fileName: string) {
    return pdfMake.createPdf(this.docDefinition).download(fileName);
  }

  printPdf() {
    return pdfMake.createPdf(this.docDefinition).print();
  }

  setPdfData(data: OrderItemResponse) {
    this.data = data;
  }

  generateOneTimePaymentPdf() {
    const header = this.generatePDFHeader();
    const companyData = this.generateCompanyContent();
    const payeeData = this.generatePayeeInformation();
    const body = this.generatePaymentDetails(PDFType.oneTime);
    const footer = this.generatePDFFooter();
    const invoiceHeader = this.generateInvoiceHeader();
    const totalPaymentDetails = this.generateTotalPayment(PDFType.oneTime);
    this.docDefinition = {
      content: [header, invoiceHeader, companyData, payeeData, body, totalPaymentDetails, footer],
      defaultStyle: {
        fontSize: 10,
      },
    };
  }

  generateRecurringPaymentPdf() {
    const header = this.generatePDFHeader();
    const companyData = this.generateCompanyContent();
    const payeeData = this.generatePayeeInformation();
    const body = this.generatePaymentDetails(PDFType.recurring);
    const footer = this.generatePDFFooter();
    const invoiceHeader = this.generateInvoiceHeader();
    const totalPaymentDetails = this.generateTotalPayment(PDFType.recurring);
    this.docDefinition = {
      content: [header, invoiceHeader, companyData, payeeData, body, totalPaymentDetails, footer],
      defaultStyle: {
        fontSize: 10,
      },
    };
  }

  private generatePDFHeader(): Content {
    return {
      table: {
        widths: [`*`],
        body: [
          [
            {
              svg: this.imageHeader,
              margin: [0, 20],
              width: 200,
            },
          ],
        ],
      },
      layout: this.generalLayout,
    };
  }

  private generatePDFFooter(): Content {
    return {
      table: {
        widths: [`*`],
        body: [
          [
            {
              text: this.footerTitle,
            },
          ],
        ],
      },
      layout: this.generalLayout,
      alignment: 'center',
      style: {
        fontSize: 8,
        color: `#707070`,
      },
    };
  }

  private generateInvoiceHeader(): Content {
    return {
      table: {
        widths: [150, `*`],
        body: [
          [
            {
              text: `TAVIS`,
              bold: true,
              fontSize: 14,
            },
            {
              text: `INVOICE NO: ${this.invoiceNumber}`,
              bold: true,
              fontSize: 14,
              margin: 0,
              style: {
                alignment: 'right',
              },
            },
          ],
        ],
      },
      layout: this.generalLayout,
    };
  }

  private generateCompanyContent(): Content {
    return {
      table: {
        widths: [`*`, 100],
        body: [
          [
            {
              text: this.companyWebsiteLink,
              margin: [0, 0, 0, 20],
              colSpan: 2,
            },
            {
              text: ``,
            },
          ],
          [
            {
              text: `Tavis Sdn Bhd (1405866 - M)`,
            },
            {
              text: `Customer Service`,
              bold: true,
            },
          ],
          [
            {
              text: `81, Jalan Maarof,`,
            },
            {
              text: `+603-2201 6686`,
            },
          ],
          [
            {
              text: `Bangsar`,
            },
            {
              text: this.supportEmail || '',
              noWrap: true,
            },
          ],
          [
            {
              text: `59000, Federal Territory of Kuala Lumpur,`,
              colSpan: 2,
            },
            {
              text: ``,
            },
          ],
          [
            {
              text: `Malaysia`,
              colSpan: 2,
            },
            {
              text: ``,
            },
          ],
        ],
      },
      layout: this.generalLayout,
      margin: [0, 0, 0, 20],
    };
  }

  private generatePayeeInformation(): Content {
    return {
      table: {
        widths: [250, `*`],
        body: [
          [
            {
              text: this.paymentDate,
              bold: true,
              margin: [0, 20, 0, 10],
              borderColor: `#fff`,
              border: [false, true, false, false],
              colSpan: 2,
            },
            {
              text: ``,
            },
          ],
          [
            {
              text: this.payeeName,
            },
            {
              text: `Payment method: ${this.paymentMethod}`,
              style: {
                alignment: 'right',
              },
            },
          ],
          [
            {
              text: this.payeeAddress,
              colSpan: 2,
            },
            {
              text: ``,
            },
          ],
          [
            {
              text: this.payeeCity,
              colSpan: 2,
            },
            {
              text: ``,
            },
          ],
          [
            {
              text: this.payeePostcodeAndState,
              colSpan: 2,
            },
            {
              text: ``,
            },
          ],
          [
            {
              text: this.payeeCountry,
              colSpan: 2,
            },
            {
              text: ``,
            },
          ],
          [
            {
              text: this.payeePhoneNumber,
              colSpan: 2,
            },
            {
              text: ``,
            },
          ],
          [
            {
              text: this.payeeEmail,
              border: [false, false, false, true],
              margin: [0, 0, 0, 20],
              colSpan: 2,
            },
            {
              text: ``,
            },
          ],
        ],
      },
      layout: this.generalLayout,
      margin: [0, 0, 0, 20],
    };
  }

  private generatePaymentDetails(type: PDFType): Content {
    let pdfBody: TableCell[][] = [];
    if (type === PDFType.recurring) {
      pdfBody = this.items.map((data, index: number) => {
        const discount = Number(data.priceInfo.discount) + Number(data.priceInfo.bundleDiscount);
        return [
          [
            {
              text: `Subscription`,
              bold: true,
            },
            {
              text: data.title,
              bold: true,
            },
            {
              text: `*1 month free trial`,
            },
            {
              text: `Starting month: `+ this.paymentMonth,
            },
            {
              text: `Billed on 1st of each month`,
            },
            {
              text: ``,
            },
          ],
          [
            {
              text: ``,
            },
            {
              text: ``,
            },
            {
              text: ``,
            },
            {
              text: ``,
            },
          ],
          [
            {
              text: `\u200B\t`, // Space with a tab, we need this empty space to contain the design
              style: {
                alignment: `right`,
              },
            },
            {
              text: `Monthly Recurring: RM ${data.priceInfo.originalPrice} `,
              style: {
                alignment: `right`,
              },
            },
            {
              text: `Discount: RM ${discount.toFixed(2)}`,
              style: {
                alignment: `right`,
              },
            },
            {
              text: `Gross Monthly Payment: RM ${data.priceInfo.finalPrice}`,
              style: {
                alignment: `right`,
              },
              margin: index !== this.items.length - 1 ? [0, 0, 0, 5] : [0, 0, 0, 20],
            },
          ],
        ];
      });
    }

    if (type === PDFType.oneTime) {
      // For this case, we need to split others and courses
      const courseItem = this.items.filter((data) => {
        return data.ordertype === OrderType.course;
      });
      const nonCourseItem = this.items.filter((data) => {
        return data.ordertype !== OrderType.course;
      });
      const coursePdfBody = courseItem.map((data, index) => {
        // Unit Price / Month
        const unitPrice = Number(data.priceInfo.originalPrice) / data.noOfMonths;
        // Discount
        const discount = Number(data.priceInfo.discount) + Number(data.priceInfo.bundleDiscount);
        return [
          [
            {
              text: `Prepaid`,
              margin: [0, 0, 0, 10],
              bold: true,
            },
            {
              text: data.title,
              bold: true,
            },
            {
              text: `Unit price / month: RM ${unitPrice.toFixed(2)}`,
            },
            {
              text: `No. of months : ${data.noOfMonths}`,
            },
          ],
          [
            {
              text: ``,
            },
            {
              text: ``,
            },
            {
              text: ``,
            },
          ],
          [
            {
              text: `\u200B\t`, // Space with a tab, we need this empty space to contain the design
              style: {
                alignment: `right`,
              },
            },
            {
              text: `Sum: RM ${Number(data.priceInfo.originalPrice).toFixed(2)}`,
              style: {
                alignment: `right`,
              },
            },
            {
              text: `Discount: RM ${discount.toFixed(2)}`,
              style: {
                alignment: `right`,
              },
            },
            {
              text: `Discounted Sum : RM ${data.priceInfo.finalPrice}`,
              style: {
                alignment: `right`,
              },
              margin: index !== courseItem.length - 1 ? [0, 0, 0, 5] : [0, 0, 0, 20],
            },
          ],
        ];
      });

      const nonCoursePdfBody = nonCourseItem.map((data, index: number) => {
        const unitPrice = Number(data.priceInfo.originalPrice);
        const discount = Number(data.priceInfo.discount) + Number(data.priceInfo.bundleDiscount);
        const discountedSum = Number(unitPrice) - Number(discount);
        return [
          [
            {
              text: data.title,
              bold: true,
            },
            {
              text: `Access : 3 years`,
            },
            {
              text: ``,
            },
          ],
          [
            {
              text: ``,
            },
            {
              text: ``,
            },
            {
              text: ``,
            },
          ],
          [
            {
              text: `Sum: RM ${Number(data.priceInfo.originalPrice).toFixed(2)}`,
              style: {
                alignment: `right`,
              },
            },
            {
              text: `Discount: RM ${discount.toFixed(2)}`,
              style: {
                alignment: `right`,
              },
            },
            {
              text: `Discounted Sum : RM ${discountedSum.toFixed(2)}`,
              style: {
                alignment: `right`,
              },
              margin: index !== nonCourseItem.length - 1 ? [0, 0, 0, 5] : [0, 0, 0, 20],
            },
          ],
        ];
      });

      if (coursePdfBody.length > 0 && nonCoursePdfBody.length > 0) {
        const itemBorder: TableCell[][] = [
          [
            {
              text: `Others`,
              bold: true,
              border: [false, true, false, false],
              margin: [0, 10, 0, 10],
            },
            {
              text: ``,
              border: [false, true, false, false],
              margin: [0, 10, 0, 10],
            },
            {
              text: ``,
              border: [false, true, false, false],
              margin: [0, 10, 0, 10],
            },
          ],
        ];
        pdfBody = [...coursePdfBody, ...itemBorder, ...nonCoursePdfBody];
      } else if (coursePdfBody.length > 0 && nonCoursePdfBody.length === 0) {
        pdfBody = coursePdfBody;
      } else if (coursePdfBody.length === 0 && nonCoursePdfBody.length > 0) {
        const itemBorder: TableCell[][] = [
          [
            {
              text: `Others`,
              bold: true,
            },
            {
              text: ``,
            },
            {
              text: ``,
            },
          ],
        ];
        pdfBody = [...itemBorder, ...nonCoursePdfBody];
      }
    }

    const padding: TableCell[][] = [
      [
        {
          text: ``,
          border: [false, true, false, false],
        },
        {
          text: ``,
          border: [false, true, false, false],
        },
        {
          text: ``,
          border: [false, true, false, false],
        },
      ],
    ];

    return {
      table: {
        widths: [`*`, 70, 200],
        body: [...pdfBody, ...padding],
      },
      layout: this.generalLayout,
      margin: [0, 0, 0, 20],
    };
  }

  private generateTotalPayment(type: PDFType): Content {
    return {
      table: {
        widths: [`*`, 150, `auto`],
        body: [
          [
            { text: `` },
            { text: `Subtotal`, margin: [0, 0, 15, 0], style: { alignment: `right` } },
            { text: `RM ${this.subtotalValue}` },
          ],
          [
            { text: `` },
            { text: `Promo Code`, margin: [0, 0, 15, 0], style: { alignment: `right` } },
            { text: this.data?.promoCode ? this.data.promoCode : '-' },
          ],
          [
            { text: `` },
            {
              text: type === PDFType.recurring ? `Monthly Recurring Payment` : `Total`,
              bold: true,
              margin: [0, 0, 15, 0],
              style: { alignment: `right` },
            },
            { text: `RM ${this.data?.totalPriceInfo.grandtotal}`, bold: true },
          ],
        ],
      },
      layout: this.generalLayout,
      margin: [0, 0, 0, 30],
    };
  }
}
