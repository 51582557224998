import { GeneralResponse, GeneralFetchParams } from './generic';

export interface BillingInformation {
  name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  country: string;
  postcode: string;
  state: string;
}

export enum OrderType {
  course = `course`,
}

export interface OrderStatusPriceInfo {
  bundleDiscount: string;
  discount: string;
  finalPrice: string;
  originalPrice: string;
}

export interface OrderStatus {
  id: number;
  name: string;
}

export interface OrderCourseItem {
  id: number;
  price: string;
  isFeatured: boolean;
  courseTimeTable: string;
  imageURL: string;
  title: string;
  ordertype: OrderType;
  priceInfo: OrderStatusPriceInfo;
  noOfMonths: number;
}

export type OrderProductItem = OrderCourseItem;

export interface PaymentMethod {
  id: number;
  name: string;
}

export interface OrderTotalPriceInfo {
  originalPrice: string;
  promoDiscount: string;
  discount: string;
  bundleDiscount: string;
  grandtotal: string;
}

export interface OrderItemResponse {
  id: number;
  gateway: string;
  date: string;
  refNo: string;
  billingInfo: BillingInformation;
  orderStatus: OrderStatus;
  items: OrderProductItem[];
  invoiceNumber: string;
  promoCode: string;
  paymentMethod: PaymentMethod;
  totalPriceInfo: OrderTotalPriceInfo;
}

export interface OrderItemDataResponse {
  objects: OrderItemResponse[];
  totalCount: number;
}

export enum PaymentGateway {
  recurring = `iPayRecurring`,
  onetime = `iPayOnlineBanking`,
}

export interface PaymentSummary {
  subTotal: string;
  bundleDiscount: string;
  promoDiscount: string;
  grandTotal: string;
}

export interface OrderDetailProductItem {
  id: number;
  title: string;
  price: string;
}

export interface OrderDetailItemResponse {
  id: number;
  gateway: PaymentGateway;
  date: string;
  refNo: string;
  billingInfo: BillingInformation;
  totalSummary: PaymentSummary;
  items: OrderDetailProductItem[];
}

export interface OrderResponse extends GeneralResponse {
  data: OrderItemDataResponse | OrderDetailItemResponse;
}

export interface OrderFetchParams extends GeneralFetchParams {
  filter: number;
}

export interface OrderListItem {
  id: number;
  date: string;
  referenceNumber: string;
  paymentType: string;
  grandTotal: string;
  billingInfo: BillingInformation;
  orderStatus: OrderStatus;
  invoiceNumber: string;
  items: OrderProductItem[];
}

export interface OrderExcelFormatItem {
  invoiceNumber: string;
  orderDate: string;
  orderType: string;
  paymentMethod: string;
  customerName: string;
  customerAddress: string;
  customerPhoneNumber: string;
  customerEmail: string;
  productName: string;
  productType: string;
  productUnitPrice: number | string;
  productCourseDuration: number | string;
  productOtherDuration: number | string;
  productSum: number | string;
  orderSubtotal: number | string;
  orderTotal: number | string;
  productMonthlyRecurring: number | string;
  productBundleDiscount: number | string;
  promoCode: string;
  totalAmount: number | string;
}
