import {
  OrderExcelFormatItem,
  OrderItemResponse,
  OrderType,
  PaymentGateway,
  BillingInformation
} from '@/models/orders';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { convertDateToOrderFormat } from './moment';

export class GenerateExcelAndCsv {
  private data: OrderItemResponse[] = [];
  private workbook: Workbook;

  constructor() {
    this.workbook = new Workbook();
    this.data = [];
  }

  private get sheetHeaders() {
    return [
      { header: 'Invoice Number', key: 'invoiceNumber' },
      { header: 'Order Date & Time', key: 'orderDate' },
      { header: 'Order Type', key: 'orderType' },
      { header: 'Payment Method', key: 'paymentMethod' },
      { header: 'Student Name', key: 'customerName' },
      { header: 'Student Address', key: 'customerAddress' },
      { header: 'Student Phone Number', key: 'customerPhoneNumber' },
      { header: 'Student Email', key: 'customerEmail' },
      { header: 'Product Name', key: 'productName' },
      { header: 'Product Type', key: 'productType' },
      { header: 'Product Unit Price per Month (RM)', key: 'productUnitPrice' },
      { header: 'Quantity (Months)', key: 'productCourseDuration' },
      { header: 'Product Misc Duration (Months)', key: 'productOtherDuration' },
      { header: 'Total (RM)', key: 'productSum' },
      { header: 'Product Monthly Recurring (RM)', key: 'productMonthlyRecurring' },
      { header: 'Product Bundle Discount (RM)', key: 'productBundleDiscount' },
      { header: 'Order Subtotal (RM)', key: 'orderSubtotal' },
      { header: 'Promo Code', key: 'promoCode' },
      { header: 'Order Total (RM)', key: 'orderTotal' },
    ];
  }

  showBillingInfo(billingInfo: BillingInformation) {
    var info = '';
    info += billingInfo.address? `${billingInfo.address},`: ``;
    info += billingInfo.city? `${billingInfo.city},`: ``;
    info += billingInfo.postcode? `${billingInfo.postcode},`: ``;
    info += billingInfo.state? `${billingInfo.state},`: ``;
    info += billingInfo.country? `${billingInfo.country},`: ``;
    return info;
  }

  setData(payload: OrderItemResponse[]) {
    this.data = payload;
  }

  /*
    Generate a new workbook and fit the data into the workbook
    */
  fitDataToWorkbook() {
    this.workbook.removeWorksheet(`Orders`);
    this.workbook.created = new Date();
    this.workbook.modified = new Date();

    const sheet = this.workbook.addWorksheet(`Orders`);
    // Add sheet headers
    sheet.columns = this.sheetHeaders;
    // Format data to fit rows easily
    const formattedData: OrderExcelFormatItem[] = [];

    if (this.data.length > 0) {
      this.data.forEach((item) => {
        if (item.items) {
          item.items.forEach((itemDetails) => {
            const unitPrice =
              itemDetails.noOfMonths > 0
                ? (Number(itemDetails.priceInfo.originalPrice) - Number(itemDetails.priceInfo.discount)) / itemDetails.noOfMonths
                : Number(itemDetails.priceInfo.originalPrice);

            formattedData.push({
              invoiceNumber: item.invoiceNumber,
              orderDate: convertDateToOrderFormat(new Date(Number(item.date) * 1000).toISOString()),
              orderType: item.gateway === PaymentGateway.recurring ? `Subscription` : `Pay-as-you-go`,
              paymentMethod: item.paymentMethod != null? item.paymentMethod.name: '',
              customerName: item.billingInfo.name,
              customerAddress: this.showBillingInfo(item.billingInfo),
              customerPhoneNumber: item.billingInfo.phone,
              customerEmail: item.billingInfo.email,
              productName: itemDetails.title,
              productType: itemDetails.ordertype.toUpperCase(),
              productUnitPrice: unitPrice.toFixed(2),
              productCourseDuration:
                itemDetails.ordertype === OrderType.course ? (itemDetails.noOfMonths == 0? '1': itemDetails.noOfMonths) : '-',
              productOtherDuration: itemDetails.ordertype === OrderType.course ? '-' : 36, // This is for products that aren't courses
              productSum:
                item.gateway === PaymentGateway.recurring
                  ? Number(itemDetails.priceInfo.finalPrice).toFixed(2)
                  : (Number(unitPrice) * itemDetails.noOfMonths).toFixed(2),
              orderTotal: Number(item.totalPriceInfo.grandtotal).toFixed(2),
              orderSubtotal: (Number(item.totalPriceInfo.originalPrice) - Number(item.totalPriceInfo.discount)).toFixed(2),
              productMonthlyRecurring:
                item.gateway === PaymentGateway.recurring
                  ? Number(itemDetails.priceInfo.originalPrice).toFixed(2)
                  : '-',
              productBundleDiscount: Number(itemDetails.priceInfo.bundleDiscount).toFixed(2),
              promoCode: item.promoCode.length > 0 ? item.promoCode : '-',
              totalAmount: itemDetails.priceInfo.finalPrice
            });
          });
        }
      });

      // Add data to sheet
      for (let index = 0; index < formattedData.length; index++) {
        sheet.addRow(formattedData[index]);
      }
    }
  }

  async exportWorkbookToExcel(fileName: string) {
    const buffer = await this.workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, fileName);
  }

  async exportWorkbookToCSV(fileName: string) {
    const buffer = await this.workbook.csv.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'text/csv',
    });
    saveAs(blob, fileName);
  }
}
