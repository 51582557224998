























import { DropdownItem } from '@/models/dropdown';
import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
@Component({})
export default class CheckboxOrderDropdown extends Vue {
  @Prop()
  isDropdownReset!: boolean;

  isAllValuesSelected = false;
  isDropdownShown = false;
  dropdownSelection: DropdownItem[] = [
    {
      text: `All`,
      value: `all`,
      uniqueKey: `_all`,
    },
    {
      text: `None`,
      value: `none`,
      uniqueKey: `_none`,
    },
  ];

  @Watch(`isDropdownReset`)
  resetDropdown() {
    if (this.isDropdownReset) {
      this.isAllValuesSelected = false;
      this.resetDropdownResetState();
    }
  }

  @Emit(`resetDropdown`)
  resetDropdownResetState() {
    return false;
  }

  @Emit(`update`)
  updateSelectedCheckboxState(value: boolean) {
    this.isAllValuesSelected = value;
    this.isDropdownShown = false;
    return this.isAllValuesSelected;
  }

  @Emit(`update`)
  updateSelectedState(item: Partial<DropdownItem>) {
    this.isAllValuesSelected = item.value === `all` ? true : false;
    this.isDropdownShown = false;
    return this.isAllValuesSelected;
  }
}
